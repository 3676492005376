import React from "react";
import { HAIR_COLORS, HairColor } from "../../types";

export default function Curly(props: { hairColor: HairColor }) {
  const color = HAIR_COLORS[props.hairColor];
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9665 32.4833C47.1798 31.7963 47.9986 30.4938 47.9986 29C47.9986 28.5071 47.9094 28.035 47.7464 27.599C49.0797 26.9503 49.9986 25.5824 49.9986 24C49.9986 21.7909 48.2077 20 45.9986 20C45.5741 20 45.1651 20.0661 44.7812 20.1886C46.1988 23.8625 46.095 28.4069 45.9665 32.4833Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
        fill={color}
      />

      <defs>
        <clipPath id="clip3_274_4378">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </>
  );
}
