import React from "react";

export default function Teeth() {
  return (
    <>
      <g clipPath="url(#clip4_274_4378)">
        <path
          d="M28.9286 46C28.9286 45.4477 29.3763 45 29.9286 45H39.0714C39.6237 45 40.0714 45.4477 40.0714 46C40.0714 48.7614 37.8329 51 35.0714 51H33.9286C31.1672 51 28.9286 48.7614 28.9286 46Z"
          fill="#66253C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7547 50.7096C36.2287 50.8976 35.662 51 35.0714 51H33.9286C31.2341 51 29.0374 48.8686 28.9325 46.1999C29.6965 45.914 30.8305 45.9469 31.9499 45.9794C32.3056 45.9897 32.6597 46 33 46C35.2092 46 37 47.567 37 49.5C37 49.9252 36.9134 50.3326 36.7547 50.7096Z"
          fill="#B03E67"
        />
        <path
          d="M30 45H40V46C40 46.5523 39.5523 47 39 47H31C30.4477 47 30 46.5523 30 46V45Z"
          fill="white"
        />

        <path
          d="M32 45.3C32 45.1343 32.1343 45 32.3 45H33.7C33.8657 45 34 45.1343 34 45.3V47.7C34 47.8657 33.8657 48 33.7 48H32.3C32.1343 48 32 47.8657 32 47.7V45.3Z"
          fill="#B03E67"
        />
      </g>
      <defs>
        <clipPath id="clip4_274_4378">
          <rect width="64" height="64" fill="white" transform="translate(1)" />
        </clipPath>
      </defs>
    </>
  );
}


